$glide-class: 'glide' !default;
$glide-element-separator: '__' !default;
$glide-modifier-separator: '--' !default;

.#{$glide-class} {
  $this: &;

  $se: $glide-element-separator;
  $sm: $glide-modifier-separator;

  position: relative;
  width: 100%;
  box-sizing: border-box;

  * {
    box-sizing: inherit;
  }

  &#{$se}track {
    overflow: hidden;
  }

  &#{$se}slides {
    position: relative;
    width: 100%;
    list-style: none;
    backface-visibility: hidden;
    transform-style: preserve-3d;
    touch-action: pan-Y;
    overflow: hidden;
    padding: 0;
    white-space: nowrap;
    display: flex;
    flex-wrap: nowrap;
    will-change: transform;

    &#{$glide-modifier-separator}dragging {
      user-select: none;
    }
  }

  &#{$se}slide {
    width: 100%;
    height: 100%;
    flex-shrink: 0;
    white-space: normal;
    user-select: none;
    -webkit-touch-callout: none;
    -webkit-tap-highlight-color: transparent;

    a {
      user-select: none;
      -webkit-user-drag: none;
      -moz-user-select: none;
      -ms-user-select: none;
    }
  }

  &#{$se}arrows {
    -webkit-touch-callout: none;
    user-select: none;
  }

  &#{$se}bullets {
    -webkit-touch-callout: none;
    user-select: none;
  }

  &#{$sm}rtl {
    direction: rtl;
  }
}
