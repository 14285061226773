article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section,
summary {
  display: block; }

audio,
canvas,
video {
  display: inline-block; }

audio:not([controls]) {
  display: none;
  height: 0; }

[hidden] {
  display: none; }

html {
  font-family: sans-serif;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%; }

body {
  margin: 0;
  -webkit-font-smoothing: antialiased; }
  body * {
    box-sizing: border-box; }

a:focus {
  outline: 0; }

a:active,
a:hover {
  outline: 0; }

h1 {
  font-size: 2em;
  margin: 0.67em 0; }

abbr[title] {
  border-bottom: 1px dotted; }

b,
strong {
  font-weight: bold; }

dfn {
  font-style: italic; }

hr {
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  height: 0; }

mark {
  background: #ff0;
  color: #000; }

code,
kbd,
pre,
samp {
  font-family: monospace, serif;
  font-size: 1em; }

pre {
  white-space: pre-wrap; }

q {
  quotes: "\201C" "\201D" "\2018" "\2019"; }

small {
  font-size: 80%; }

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sup {
  top: -0.5em; }

sub {
  bottom: -0.25em; }

img {
  border: 0; }

svg:not(:root) {
  overflow: hidden; }

figure {
  margin: 0; }

fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em; }

legend {
  border: 0;
  padding: 0; }

button,
input,
select,
textarea {
  font-family: inherit;
  font-size: 100%;
  margin: 0; }

button,
input {
  line-height: normal; }

button,
select {
  text-transform: none; }

button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button;
  cursor: pointer; }

button[disabled],
html input[disabled] {
  cursor: default; }

input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box;
  padding: 0; }

input[type="search"] {
  -webkit-appearance: textfield;
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box;
  box-sizing: content-box; }

input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0; }

textarea {
  overflow: auto;
  vertical-align: top; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

.clearfix:after {
  content: ".";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0; }

.clearfix {
  display: inline-block; }

html[xmlns] .clearfix {
  display: block; }

* html .clearfix {
  height: 1%; }

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  font-weight: normal; }

ul {
  margin: 0;
  padding: 0; }

/**
 * May this be a helping hand in the land of media queries.
 * Nothing is a waste of time if you use the experience wisely.
 */
/**
 * Retina
 */
.text-black {
  color: #000000 !important; }

.bg-black {
  background-color: #000000; }

.\:h-bg-black:hover {
  background-color: #000000; }

.\:h-bg-darken-black:hover {
  background-color: black !important; }

.\:h-text-black:hover {
  color: #000000; }

.\:h-border-black:hover {
  border-color: #000000; }

.border-black {
  border-color: #000000; }

.border-full-black {
  border: 1px solid #000000; }

.border-top-black {
  border-top: 1px solid #000000; }

.border-bottom-black {
  border-bottom: 1px solid #000000; }

.text-white {
  color: #FFFFFF !important; }

.bg-white {
  background-color: #FFFFFF; }

.\:h-bg-white:hover {
  background-color: #FFFFFF; }

.\:h-bg-darken-white:hover {
  background-color: #f2f2f2 !important; }

.\:h-text-white:hover {
  color: #FFFFFF; }

.\:h-border-white:hover {
  border-color: #FFFFFF; }

.border-white {
  border-color: #FFFFFF; }

.border-full-white {
  border: 1px solid #FFFFFF; }

.border-top-white {
  border-top: 1px solid #FFFFFF; }

.border-bottom-white {
  border-bottom: 1px solid #FFFFFF; }

.text-orange {
  color: #ff7300 !important; }

.bg-orange {
  background-color: #ff7300; }

.\:h-bg-orange:hover {
  background-color: #ff7300; }

.\:h-bg-darken-orange:hover {
  background-color: #e66800 !important; }

.\:h-text-orange:hover {
  color: #ff7300; }

.\:h-border-orange:hover {
  border-color: #ff7300; }

.border-orange {
  border-color: #ff7300; }

.border-full-orange {
  border: 1px solid #ff7300; }

.border-top-orange {
  border-top: 1px solid #ff7300; }

.border-bottom-orange {
  border-bottom: 1px solid #ff7300; }

.text-blue {
  color: #1C5CEB !important; }

.bg-blue {
  background-color: #1C5CEB; }

.\:h-bg-blue:hover {
  background-color: #1C5CEB; }

.\:h-bg-darken-blue:hover {
  background-color: #1351da !important; }

.\:h-text-blue:hover {
  color: #1C5CEB; }

.\:h-border-blue:hover {
  border-color: #1C5CEB; }

.border-blue {
  border-color: #1C5CEB; }

.border-full-blue {
  border: 1px solid #1C5CEB; }

.border-top-blue {
  border-top: 1px solid #1C5CEB; }

.border-bottom-blue {
  border-bottom: 1px solid #1C5CEB; }

.text-pink {
  color: #F71A5E !important; }

.bg-pink {
  background-color: #F71A5E; }

.\:h-bg-pink:hover {
  background-color: #F71A5E; }

.\:h-bg-darken-pink:hover {
  background-color: #ef084f !important; }

.\:h-text-pink:hover {
  color: #F71A5E; }

.\:h-border-pink:hover {
  border-color: #F71A5E; }

.border-pink {
  border-color: #F71A5E; }

.border-full-pink {
  border: 1px solid #F71A5E; }

.border-top-pink {
  border-top: 1px solid #F71A5E; }

.border-bottom-pink {
  border-bottom: 1px solid #F71A5E; }

.text-gray {
  color: #FBFBFB !important; }

.bg-gray {
  background-color: #FBFBFB; }

.\:h-bg-gray:hover {
  background-color: #FBFBFB; }

.\:h-bg-darken-gray:hover {
  background-color: #eeeeee !important; }

.\:h-text-gray:hover {
  color: #FBFBFB; }

.\:h-border-gray:hover {
  border-color: #FBFBFB; }

.border-gray {
  border-color: #FBFBFB; }

.border-full-gray {
  border: 1px solid #FBFBFB; }

.border-top-gray {
  border-top: 1px solid #FBFBFB; }

.border-bottom-gray {
  border-bottom: 1px solid #FBFBFB; }

.holder {
  width: 1280px;
  max-width: 90%;
  margin: 0 auto;
  position: relative; }

#slogans {
  margin: 80px 0;
  position: relative; }

@font-face {
  font-family: 'Apercu Mono';
  src: url("../fonts/ApercuMono.eot");
  /* IE9 Compat Modes */
  src: url("../fonts/ApercuMono.eot?#iefix") format("embedded-opentype"), url("../fonts/ApercuMono.woff") format("woff"), url("../fonts/ApercuMono.ttf") format("truetype"), url("../fonts/ApercuMono.svg#3371cb1543e8bb5dfc81cdf8c2631ef4") format("svg");
  /* Legacy iOS */
  font-style: normal;
  font-weight: 400; }

@font-face {
  font-family: 'Prohibition Round';
  src: url("../fonts/HoldFastFoundryProhibitionRound.eot");
  /* IE9 Compat Modes */
  src: url("../fonts/HoldFastFoundryProhibitionRound.eot?#iefix") format("embedded-opentype"), url("../fonts/HoldFastFoundryProhibitionRound.woff") format("woff"), url("../fonts/HoldFastFoundryProhibitionRound.ttf") format("truetype"), url("../fonts/HoldFastFoundryProhibitionRound.svg#dfdc232a17e70a09f175c75fb3caec26") format("svg");
  /* Legacy iOS */
  font-style: normal;
  font-weight: 400; }

/**
 *  Margin
 */
.mla {
  margin-left: auto !important; }

.m0 {
  margin: 0 !important; }

.mra {
  margin-right: auto !important; }

.m-auto {
  margin: 0 auto; }

.mt-1 {
  margin-top: 10px; }

.mt-2 {
  margin-top: 20px; }

.mt-3 {
  margin-top: 30px; }

.mt-4 {
  margin-top: 40px; }

.mt-5 {
  margin-top: 50px; }
  @media only screen and (max-width: 500px) {
    .mt-5 {
      margin-top: 40px; } }

.mt-6 {
  margin-top: 60px; }
  @media only screen and (max-width: 500px) {
    .mt-6 {
      margin-top: 40px; } }

.mt-7 {
  margin-top: 70px; }
  @media only screen and (max-width: 500px) {
    .mt-7 {
      margin-top: 40px; } }

.mt-8 {
  margin-top: 80px; }
  @media only screen and (max-width: 500px) {
    .mt-8 {
      margin-top: 40px; } }

.mt-block {
  margin-top: 120px; }
  @media only screen and (max-width: 500px) {
    .mt-block {
      margin-top: 40px; } }

.m-block {
  margin: 120px auto; }
  @media only screen and (max-width: 800px) {
    .m-block {
      margin: 60px auto; } }

.m-block-auto {
  margin: 120px 0; }
  @media only screen and (max-width: 800px) {
    .m-block-auto {
      margin: 60px 0; } }

.my-4 {
  margin: 40px 0; }
  @media only screen and (max-width: 800px) {
    .my-4 {
      margin: 30px 0 !important; } }

.my-6 {
  margin: 60px 0; }
  @media only screen and (max-width: 800px) {
    .my-6 {
      margin: 50px 0 !important; } }

.my-8 {
  margin: 80px 0; }
  @media only screen and (max-width: 800px) {
    .my-8 {
      margin: 60px 0 !important; } }

.mb-1 {
  margin-bottom: 10px; }

.mb-2 {
  margin-bottom: 20px; }

.mb-3 {
  margin-bottom: 30px; }

.mb-4 {
  margin-bottom: 40px; }

.mb-5 {
  margin-bottom: 50px; }
  @media only screen and (max-width: 500px) {
    .mb-5 {
      margin-bottom: 40px; } }

.mb-6 {
  margin-bottom: 60px; }
  @media only screen and (max-width: 500px) {
    .mb-6 {
      margin-bottom: 40px; } }

.mb-7 {
  margin-bottom: 70px; }
  @media only screen and (max-width: 500px) {
    .mb-7 {
      margin-bottom: 40px; } }

.mb-8, .section-title, .campaign:last-of-type, .places .place {
  margin-bottom: 80px; }
  @media only screen and (max-width: 500px) {
    .mb-8, .section-title, .campaign:last-of-type, .places .place {
      margin-bottom: 40px; } }

.mb-block {
  margin-bottom: 120px; }
  @media only screen and (max-width: 500px) {
    .mb-block {
      margin-bottom: 40px; } }

.ml-1 {
  margin-left: 10px; }

.mx-1 {
  margin: 0 10px; }

.mx-auto {
  margin-left: auto;
  margin-right: auto; }

@media only screen and (max-width: 500px) {
  .m-nm {
    margin: 0 !important; } }

@media only screen and (max-width: 800px) {
  .m-mb2 {
    margin-bottom: 20px !important; } }

/**
 *  Padding
 */
.pt-block {
  padding-top: 105px; }
  @media only screen and (max-width: 500px) {
    .pt-block {
      padding-top: 40px; } }

.pb-block {
  padding-bottom: 105px; }
  @media only screen and (max-width: 500px) {
    .pb-block {
      padding-bottom: 40px; } }

.p-block {
  padding: 105px 0; }
  @media only screen and (max-width: 500px) {
    .p-block {
      padding: 40px 0; } }

.py-block-l {
  padding: 177px 0; }
  @media only screen and (max-width: 500px) {
    .py-block-l {
      padding: 40px 0; } }

.py-block-md {
  padding: 120px 0; }
  @media only screen and (max-width: 500px) {
    .py-block-md {
      padding: 60px 0; } }

.py-block-s {
  padding: 80px 0; }
  @media only screen and (max-width: 500px) {
    .py-block-s {
      padding: 40px 0; } }

.py-4 {
  padding: 40px 0; }

.py-5 {
  padding: 50px 0; }
  @media only screen and (max-width: 800px) {
    .py-5 {
      padding: 40px 0; } }

.py-7 {
  padding: 70px 0; }
  @media only screen and (max-width: 800px) {
    .py-7 {
      padding: 35px 0; } }

.p-20 {
  padding: 20px; }
  @media only screen and (max-width: 500px) {
    .p-20 {
      padding: 0; } }

@media only screen and (max-width: 800px) {
  .m-no-pad {
    padding: 0 !important; } }

@media only screen and (max-width: 800px) {
  .m-px-20 {
    padding: 0 20px !important; } }

.pb-4 {
  padding-bottom: 40px; }

.pb-6 {
  padding-bottom: 60px; }

.pt-6 {
  padding-top: 60px; }

.px-1 {
  padding: 0 10px; }

.py-1 {
  padding: 10px 0; }

.py-50 {
  padding: 50px 0; }

.py-80 {
  padding: 80px 0; }

.py-100 {
  padding: 100px 0; }

.px-2 {
  padding-left: 20px;
  padding-right: 20px; }

.pl-80 {
  padding-left: 100px; }

.pr-80 {
  padding-right: 100px; }

.pr-170 {
  padding-right: 170px; }

.pl-170 {
  padding-left: 170px; }

.pr-100 {
  padding-right: 100px; }

.bold {
  font-weight: bold; }

.title-font {
  font-family: "Prohibition Round", sans-serif; }

.body-font {
  font-family: "Apercu Mono", monospace; }

.t-xl {
  font-size: 160px;
  line-height: 100px; }
  @media only screen and (max-width: 800px) {
    .t-xl {
      font-size: 40px;
      line-height: 30px; } }

.t-l {
  font-size: 68px;
  line-height: auto; }
  @media only screen and (max-width: 800px) {
    .t-l {
      font-size: 40px;
      line-height: auto; } }

.t-xmd {
  font-size: 58px;
  line-height: auto; }
  @media only screen and (max-width: 800px) {
    .t-xmd {
      font-size: 40px;
      line-height: auto; } }

.t-md {
  font-size: 36px;
  line-height: auto; }
  @media only screen and (max-width: 800px) {
    .t-md {
      font-size: 28px;
      line-height: auto; } }

.t-std {
  font-size: 24px;
  line-height: 38px; }
  @media only screen and (max-width: 800px) {
    .t-std {
      font-size: 18px;
      line-height: auto; } }

.t-s {
  font-size: 18px;
  line-height: 28px; }
  @media only screen and (max-width: 800px) {
    .t-s {
      font-size: 16px;
      line-height: 24px; } }

.t-xs {
  font-size: 16px;
  line-height: 24px; }
  @media only screen and (max-width: 800px) {
    .t-xs {
      font-size: 14px;
      line-height: 22px; } }

.uppercase {
  text-transform: uppercase; }

.underline {
  text-decoration: underline !important; }

.t-center {
  text-align: center; }

.flex-reverse {
  flex-direction: row-reverse; }

.block {
  display: block; }

.relative {
  position: relative; }

.section-title {
  position: relative;
  margin-top: 200px; }
  @media only screen and (max-width: 500px) {
    .section-title {
      margin-top: 80px; } }
  .section-title h2 {
    background: white;
    position: relative;
    z-index: 5;
    padding-right: 30px;
    display: inline-block; }
  .section-title:after {
    content: '';
    width: 100%;
    height: 3px;
    z-index: 2;
    background: #000000;
    position: absolute;
    top: 50%;
    margin-bottom: -3px;
    left: 0; }

.ilb {
  display: inline-block; }

.glide {
  position: relative;
  width: 100%;
  box-sizing: border-box; }
  .glide * {
    box-sizing: inherit; }
  .glide__track {
    overflow: hidden; }
  .glide__slides {
    position: relative;
    width: 100%;
    list-style: none;
    backface-visibility: hidden;
    transform-style: preserve-3d;
    touch-action: pan-Y;
    overflow: hidden;
    padding: 0;
    white-space: nowrap;
    display: flex;
    flex-wrap: nowrap;
    will-change: transform; }
    .glide__slides--dragging {
      user-select: none; }
  .glide__slide {
    width: 100%;
    height: 100%;
    flex-shrink: 0;
    white-space: normal;
    user-select: none;
    -webkit-touch-callout: none;
    -webkit-tap-highlight-color: transparent; }
    .glide__slide a {
      user-select: none;
      -webkit-user-drag: none;
      -moz-user-select: none;
      -ms-user-select: none; }
  .glide__arrows {
    -webkit-touch-callout: none;
    user-select: none; }
  .glide__bullets {
    -webkit-touch-callout: none;
    user-select: none; }
  .glide--rtl {
    direction: rtl; }

.button {
  color: inherit;
  text-decoration: none;
  font-size: 24px;
  line-height: 24px;
  min-width: 250px;
  padding: 20px 0 22px 0;
  display: inline-block;
  border-radius: 4px;
  text-align: center; }
  .button.big {
    padding: 20px 60px 22px 60px;
    font-size: 28px; }
  .button.small {
    font-size: 16px;
    padding: 12px 0 13px 0;
    min-width: 160px; }
  @media only screen and (max-width: 500px) {
    .button {
      font-size: 16px !important; } }

.top-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 24px;
  left: 50%;
  z-index: 99;
  transform: translateX(-50%); }
  .top-bar a {
    text-decoration: none; }
    @media only screen and (max-width: 800px) {
      .top-bar a img {
        max-width: 50px; } }
  @media only screen and (max-width: 800px) {
    .top-bar {
      top: 24px; } }

.intro {
  padding: 240px 0; }
  @media only screen and (max-width: 800px) {
    .intro {
      padding: 120px 0; } }

.lunch-wrap {
  padding: 67px 0; }
  .lunch-wrap h2 {
    max-width: 420px; }
  .lunch-wrap .lunch-illu {
    position: absolute;
    right: 0;
    top: -108px;
    width: 386px; }
    @media only screen and (max-width: 1100px) {
      .lunch-wrap .lunch-illu {
        display: none; } }

.full-lunch {
  display: none; }
  .full-lunch.visible {
    display: block; }

.campaign {
  display: flex;
  justify-content: space-between;
  align-items: center; }
  .campaign .copy, .campaign .image {
    width: 45%; }
    .campaign .copy img, .campaign .image img {
      width: 100%; }
  @media only screen and (max-width: 800px) {
    .campaign {
      flex-direction: column; }
      .campaign .copy, .campaign .image {
        width: 100%; } }

.card-wrap {
  display: flex;
  justify-content: space-between;
  margin-top: 150px; }
  .card-wrap .card {
    width: 32%;
    padding: 48px 24px 200px 24px;
    border-radius: 4px;
    text-decoration: none;
    position: relative; }
    .card-wrap .card img {
      height: 207px;
      width: auto;
      position: absolute;
      right: -30px;
      bottom: -20px; }
  @media only screen and (max-width: 500px) {
    .card-wrap {
      flex-direction: column; }
      .card-wrap .card {
        width: 100%;
        margin-bottom: 20px; } }

.places {
  display: flex;
  flex-wrap: wrap; }
  .places .place {
    width: 32%; }
    @media only screen and (max-width: 500px) {
      .places .place {
        width: 100%; } }

.footer-links {
  display: flex; }
  @media only screen and (max-width: 500px) {
    .footer-links {
      flex-wrap: wrap; } }

.links {
  width: 32%; }
  @media only screen and (max-width: 500px) {
    .links {
      width: 100%;
      margin-bottom: 20px; } }

.footer {
  position: relative;
  overflow: hidden; }
  .footer img {
    position: absolute;
    right: 5%;
    top: -30px; }
    @media only screen and (max-width: 800px) {
      .footer img {
        display: none; } }

.text-holder {
  width: 710px;
  margin: 0;
  max-width: 100%; }

.page-top {
  padding-top: 230px;
  overflow-x: hidden; }
  @media only screen and (max-width: 500px) {
    .page-top {
      padding-top: 120px; } }

.image-box {
  position: relative;
  z-index: 2; }
  .image-box:after {
    content: '';
    width: 187px;
    height: 3px;
    background: black;
    position: absolute;
    right: -15%;
    z-index: 0;
    top: 60%;
    transform: translateY(-50%); }

.mw-text {
  max-width: 600px; }
  @media only screen and (max-width: 1050px) {
    .mw-text {
      max-width: 100%; } }

.event img {
  margin-top: 50px;
  position: relative;
  z-index: 3; }

.event p {
  max-width: 300px;
  margin: 24px auto 0px auto; }

.cities {
  position: relative; }
  .cities a {
    text-decoration: none;
    margin-right: 24px; }
    .cities a:hover {
      opacity: 0.8; }
    .cities a.inactive {
      cursor: not-allowed;
      pointer-events: none;
      opacity: 0.5; }
    .cities a.active {
      color: #ff7300 !important;
      text-decoration: underline;
      opacity: 1; }

.job {
  background: white;
  max-width: 820px;
  padding: 22px 26px 22px 27px; }
  .job:hover {
    background: #f2f2f2; }
  .job:nth-child(odd) {
    background: #FBFBFB; }
    .job:nth-child(odd):hover {
      background: #eeeeee; }
  .job .heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer; }
  .job .job-description {
    display: none; }
    .job .job-description.visible {
      display: block; }

#logo, #burger {
  display: block; }

#logoSec, #burgerSec {
  display: none; }

.fullscreen-navigation {
  display: none;
  transform: scale(0) translate3d(0, 0, 0);
  transform-origin: top right;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 98;
  transition: all .3s; }
  .fullscreen-navigation.visible {
    display: block;
    transform: scale(1) translate3d(0, 0, 0); }
  .fullscreen-navigation .holder {
    position: relative;
    padding-top: 200px;
    opacity: 0;
    transform: translate3d(0, 0, 0);
    z-index: 98;
    transition: opacity .2s;
    transition-delay: 0s; }
    .fullscreen-navigation .holder.visible {
      opacity: 1;
      transition-delay: .3s; }
    @media only screen and (max-width: 1500px) {
      .fullscreen-navigation .holder {
        padding-top: 100px; } }
  .fullscreen-navigation .menu-items {
    width: 60%;
    position: relative;
    z-index: 98;
    transform: translate3d(0, 0, 0); }
    @media only screen and (max-width: 800px) {
      .fullscreen-navigation .menu-items {
        width: 100%; } }
    .fullscreen-navigation .menu-items h2, .fullscreen-navigation .menu-items p, .fullscreen-navigation .menu-items a {
      position: relative;
      z-index: 98;
      transform: translate3d(0, 0, 0); }
    .fullscreen-navigation .menu-items h2 {
      font-size: 90px; }
      @media only screen and (max-width: 1500px) {
        .fullscreen-navigation .menu-items h2 {
          font-size: 40px; } }
    .fullscreen-navigation .menu-items p {
      font-size: 30px; }
      @media only screen and (max-width: 1500px) {
        .fullscreen-navigation .menu-items p {
          font-size: 20px; } }
    .fullscreen-navigation .menu-items a {
      text-decoration: none;
      display: block; }
  .fullscreen-navigation .menu-places {
    width: 18%; }
    @media only screen and (max-width: 800px) {
      .fullscreen-navigation .menu-places {
        display: none; } }
    .fullscreen-navigation .menu-places .place {
      margin-bottom: 20px; }
    @media only screen and (max-width: 1500px) {
      .fullscreen-navigation .menu-places {
        width: 40%; }
        .fullscreen-navigation .menu-places .place {
          margin-bottom: 10px; } }
  .fullscreen-navigation .menu-illu {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%; }

.nav-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  z-index: 98; }

.intro #mugAnim {
  position: absolute;
  bottom: 20%;
  left: -10%;
  width: 320px; }
  @media only screen and (max-width: 800px) {
    .intro #mugAnim {
      width: 90px;
      bottom: -0%; } }

.intro #loek {
  position: absolute;
  top: 20%;
  right: 0%;
  animation: spin 8s linear infinite; }
  .intro #loek img {
    width: 160px; }
  @media only screen and (max-width: 800px) {
    .intro #loek img {
      width: 80px; } }

.text-body #mugAnim {
  position: absolute;
  bottom: 20%;
  right: -10%;
  width: 320px; }
  @media only screen and (max-width: 1000px) {
    .text-body #mugAnim {
      display: none; } }

.text-body #loek {
  position: absolute;
  top: 0%;
  right: 20%;
  animation: spin 8s linear infinite; }
  .text-body #loek img {
    width: 160px; }
  @media only screen and (max-width: 1000px) {
    .text-body #loek {
      display: none; } }

@keyframes spin {
  100% {
    transform: rotate(360deg); } }

#menuAnim {
  transform: translateY(0%); }
  @media only screen and (min-width: 1500px) {
    #menuAnim {
      transform: translateY(20%); } }
  @media only screen and (max-width: 500px) {
    #menuAnim {
      transform: translateY(200%); } }

.dining-holder {
  display: flex;
  flex-wrap: wrap; }

.menu-col {
  width: 50%; }
  @media only screen and (max-width: 500px) {
    .menu-col {
      width: 100%; } }

.food-description {
  display: none;
  max-width: 60%; }
  .food-description.active {
    display: block; }

.js-expand-food {
  cursor: pointer; }

.double img, .double span {
  display: inline-block; }

.double img {
  width: 35px; }

.max-w-2xl {
  max-width: 42rem; }
  @media only screen and (max-width: 800px) {
    .max-w-2xl {
      max-width: 100%; } }

.menu-right {
  display: flex;
  align-items: center; }

.online-button {
  color: inherit;
  text-decoration: none;
  font-size: 16px;
  padding: 12px 0 13px 0;
  min-width: 160px;
  display: inline-block;
  border-radius: 4px;
  text-align: center;
  margin-right: 48px; }
  @media only screen and (max-width: 500px) {
    .online-button {
      font-size: 16px !important; } }

.top-banner {
  padding: 12px 20px;
  color: white;
  font-family: "Apercu Mono", monospace;
  font-weight: bold;
  font-size: 18px;
  border-radius: 8px; }
  @media only screen and (max-width: 500px) {
    .top-banner {
      font-size: 13px;
      position: absolute;
      top: 125%;
      left: 50%;
      transform: translateX(-50%);
      width: 100%; } }
