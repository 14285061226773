@import 'normalize';
@import 'breaker';
@import 'mixins';

// Gone is the desktop, table and mobile. Let's use s, m, l, xl.
$s: 500px;
$m: 800px;
$l: 1050px;
$xl: 1410px;

// Default content settings.
$contentWidth: 1030px;
$contentPadding: 15px;
$contentMaxWidth: 90%;

// Font weights.
$regular: 400;
$medium: 500;
$semibold: 600;
$bold: 700;

// Colors
$colors: (
  black:#000000,
  white: #FFFFFF,
  orange: #ff7300,
  blue: #1C5CEB,
  pink: #F71A5E,
  gray: #FBFBFB
);

@each $name, $value in $colors {
  .text-#{$name} {
    color: $value !important;
  }
  .bg-#{$name} {
    background-color: $value;
  }
  .\:h-bg-#{$name}:hover {
    background-color:$value;
  }
  .\:h-bg-darken-#{$name}:hover {
    background-color:darken($value, 5%) !important;
  }
  .\:h-text-#{$name}:hover {
    color:$value;
  }
  .\:h-border-#{$name}:hover {
    border-color:$value;
  }
  .border-#{$name} {
    border-color: $value;
  }
  .border-full-#{$name} {
    border:1px solid $value;
  }
  .border-top-#{$name} {
    border-top:1px solid $value;
  }
  .border-bottom-#{$name} {
    border-bottom:1px solid $value;
  }
}

.holder {
  width:1280px;
  max-width:90%;
  margin:0 auto;
  position:relative;
}

#slogans {
  margin:80px 0;
  position:relative;
}

@font-face {
  font-family: 'Apercu Mono';
  src: url('../fonts/ApercuMono.eot'); /* IE9 Compat Modes */
  src: url('../fonts/ApercuMono.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/ApercuMono.woff') format('woff'), /* Modern Browsers */
       url('../fonts/ApercuMono.ttf')  format('truetype'), /* Safari, Android, iOS */
       url('../fonts/ApercuMono.svg#3371cb1543e8bb5dfc81cdf8c2631ef4') format('svg'); /* Legacy iOS */

  font-style:   normal;
  font-weight:  400;
}

@font-face {
  font-family: 'Prohibition Round';
  src: url('../fonts/HoldFastFoundryProhibitionRound.eot'); /* IE9 Compat Modes */
  src: url('../fonts/HoldFastFoundryProhibitionRound.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/HoldFastFoundryProhibitionRound.woff') format('woff'), /* Modern Browsers */
       url('../fonts/HoldFastFoundryProhibitionRound.ttf')  format('truetype'), /* Safari, Android, iOS */
       url('../fonts/HoldFastFoundryProhibitionRound.svg#dfdc232a17e70a09f175c75fb3caec26') format('svg'); /* Legacy iOS */

  font-style:   normal;
  font-weight:  400;
}
$bodyFont: 'Apercu Mono', monospace;
$titleFont: 'Prohibition Round', sans-serif;
@import 'utils';
@import "glide";
//Text stuff


//Buttons
.button {
  color:inherit;
  text-decoration: none;
  font-size:24px;
  line-height:24px;
  min-width:250px;
  padding:20px 0 22px 0;
  display:inline-block;
  border-radius:4px;
  text-align:center;
  &.big {
    padding:20px 60px 22px 60px;
    font-size:28px;
  }
  &.small {
    font-size:16px;
    padding:12px 0 13px 0;
    min-width:160px;
  }
  @include below($s) {
    font-size:16px !important;
  }
}


// Page content
.top-bar {
  display:flex;
  justify-content: space-between;
  align-items: center;
  position:fixed;
  top:24px;
  left:50%;
  z-index:99;
  transform:translateX(-50%);
  a {
    text-decoration: none;
    @include below($m) {
      img {
        max-width:50px;
      }
    }
  }
  @include below($m) {
    top:24px;
  }
}
.intro {
  padding:240px 0;
  @include below($m) {
    padding:120px 0;
  }
}
.lunch-wrap {
  padding:67px 0;
  h2 {
    max-width:420px;
  }
  .lunch-illu {
    position:absolute;
    right:0;
    top:-108px;
    width:386px;
    @include below(1100px) {
      display:none;
    }
  }
}
.full-lunch {
  display:none;
  &.visible {
    display:block;
  }
}
.campaign {
  display:flex;
  justify-content: space-between;

  align-items: center;
  .copy, .image {
    width:45%;
    img {
      width:100%;
    }
  }
  &:last-of-type {
    @extend .mb-8;
  }
  @include below($m) {
    flex-direction: column;
    .copy, .image {
      width:100%;
    }
  }
}

.card-wrap {
  display:flex;
  justify-content:space-between;
  margin-top:150px;
  .card {
    width:32%;
    padding:48px 24px 200px 24px;
    border-radius:4px;
    text-decoration: none;
    position:relative;
    img {
      height:207px;
      width:auto;
      position:absolute;
      right:-30px;
      bottom:-20px;
    }
  }
  @include below($s) {
    flex-direction: column;
    .card {
      width:100%;
      margin-bottom:20px;
    }
  }
}
.places {
  display:flex;
  flex-wrap:wrap;
  .place {
    width:32%;
    @extend .mb-8;
    @include below($s) {
      width:100%;
    }
  }
}
.footer-links {
  display:flex;
  @include below($s) {
    flex-wrap:wrap;
  }
}
.links {
  width:32%;
  @include below($s) {
    width:100%;
    margin-bottom:20px;
  }
}
.footer {
  position:relative;
  overflow:hidden;
  img {
    position:absolute;
    right:5%;
    top:-30px;
    @include below($m) {
      display:none;
    }
  }
}
.text-holder {
    width:710px;
    margin:0;
    max-width:100%;
  }
.page-top {
  padding-top:230px;
  overflow-x:hidden;
  @include below($s) {
    padding-top:120px;
  }
}
.image-box {
  position:relative;
  z-index:2;
  &:after {
    content:'';
    width:187px;
    height:3px;
    background:black;
    position:absolute;
    right:-15%;
    z-index:0;
    top:60%;
    transform:translateY(-50%);
  }
}
.mw-text {
  max-width:600px;
  @include below($l) {
    max-width:100%;
  }
}
.event img {
  margin-top:50px;
  position: relative;
  z-index:3;
}
.event p {
  max-width:300px;
  margin:24px auto 0px auto;
}
.cities {
  position: relative;
  a {
    text-decoration: none;
    margin-right:24px;
    &:hover {
      opacity:0.8;
    }
    &.inactive {
      cursor: not-allowed;
      pointer-events: none;
      opacity:0.5;
    }
    &.active {
      color:map-get($colors, orange) !important;
      text-decoration: underline;
      opacity:1;
    }
  }
}

.job {
  background:white;
  max-width:820px;
  padding:22px 26px 22px 27px;
  &:hover {
    background:darken(white, 5%);
  }
  &:nth-child(odd) {
    background:map-get($colors, gray);
    &:hover {
      background:darken(map-get($colors, gray), 5%);
    }
  }
  .heading {
    display:flex;
    align-items:center;
    justify-content: space-between;
    cursor:pointer;
  }
  .job-description {
    display:none;
    &.visible {
      display:block;
    }
  }
}
#logo, #burger {
  display:block;
}
#logoSec, #burgerSec {
  display:none;
}
.fullscreen-navigation {
  display:none;
  transform:scale(0) translate3d(0,0,0);
  transform-origin:top right;
  position:fixed;
  top:0;
  left:0;
  height:100%;
  width:100%;
  z-index:98;
  transition:all .3s;
  &.visible {
    display:block;
    transform:scale(1) translate3d(0,0,0);
  }
  .holder {
    position:relative;
    padding-top:200px;
    opacity:0;
    transform:translate3d(0,0,0);
    z-index:98;
    transition:opacity .2s;
    transition-delay: 0s;
    &.visible {
      opacity:1;
      transition-delay: .3s;
    }
    @include below(1500px) {
      padding-top:100px;
    }
  }
  .menu-items {
    width:60%;
    position:relative;
    z-index:98;
    transform:translate3d(0,0,0);
    @include below($m) {
      width:100%;
    }
    h2, p, a {
      position:relative;
      z-index:98;
      transform:translate3d(0,0,0);
    }
    h2 {
      font-size:90px;
       @include below(1500px) {
        font-size:40px;
      }
    }
    p {
      font-size:30px;
      @include below(1500px) {
        font-size:20px;
      }
    }
    a {
      text-decoration: none;
      display:block;
    }
  }
  .menu-places {
    width:18%;
    @include below($m) {
      display:none;
    }
    .place {
      margin-bottom:20px;
    }
     @include below(1500px) {
      width:40%;
      .place {
        margin-bottom:10px;
      }
    }
  }
  .menu-illu {
    position:absolute;
    bottom:0;
    left:0;
    width:100%;
  }
}
.nav-wrap {
  display:flex;
  align-items:center;
  justify-content:space-between;
  position:relative;
  z-index:98;
}
.intro #mugAnim {
  position:absolute;
  bottom:20%;
  left:-10%;
  width:320px;
  @include below($m) {
    width:90px;
    bottom:-0%;
  }
}
.intro #loek {
  position:absolute;
  top:20%;
  right:0%;
  animation:spin 8s linear infinite;
  img {
    width:160px;
  }
  @include below($m) {
    img {
      width:80px;
    }
  }
}
.text-body #mugAnim {
  position:absolute;
  bottom:20%;
  right:-10%;
  width:320px;
  @include below(1000px) {
    display:none;
  }
}
.text-body #loek {
  position:absolute;
  top:0%;
  right:20%;
  animation:spin 8s linear infinite;
  img {
    width:160px;
  }
  @include below(1000px) {
    display:none;
  }
}
@keyframes spin {
  100% {
    transform:rotate(360deg);
  }
}
#menuAnim {
  transform:translateY(0%);
  @include above(1500px) {
    transform:translateY(20%);
  }
  @include below($s) {
    transform:translateY(200%);
  }
}

.dining-holder {
  display:flex;
  flex-wrap:wrap;
}
.menu-col {
  width:50%;
  @include below($s) {
    width:100%;
  }
}
.food-description {
  display:none;
  max-width:60%;
  &.active {
    display:block;
  }
}
.js-expand-food {
  cursor:pointer;
}
.double {
  img, span {
    display:inline-block;
  }
  img {
    width:35px;
  }
}
.max-w-2xl {
  max-width:42rem;
  @include below($m) {
    max-width:100%;
  }
}

.menu-right {
  display:flex;
  align-items:center;
}
.online-button {
  color:inherit;
  text-decoration: none;
  font-size:16px;
  padding:12px 0 13px 0;
  min-width:160px;
  display:inline-block;
  border-radius:4px;
  text-align:center;
  margin-right:48px;
  @include below($s) {
    font-size:16px !important;
  }
}
.top-banner {
  padding:12px 20px;
  color:white;
  font-family: $bodyFont;
  font-weight:bold;
  font-size:18px;
  border-radius:8px;
  @include below($s) {
    font-size:13px;
    position:absolute;
    top:125%;
    left:50%;
    transform:translateX(-50%);
    width:100%;
  }
}