/**
 *  Margin
 */
 .mla {
  margin-left:auto !important;
}
.m0  {
  margin:0 !important;
}
.mra {
  margin-right:auto !important;
}
.m-auto {
    margin: 0 auto;
}
.mt-1 {
  margin-top:10px;
}
.mt-2 {
  margin-top:20px;
}
.mt-3 {
  margin-top:30px;
}
.mt-4 {
  margin-top:40px;
}
.mt-5 {
  margin-top:50px;
  @include below($s) {
    margin-top:40px;
  }
}
.mt-6 {
  margin-top:60px;
  @include below($s) {
    margin-top:40px;
  }
}
.mt-7 {
  margin-top:70px;
  @include below($s) {
    margin-top:40px;
  }
}
.mt-8 {
  margin-top:80px;
  @include below($s) {
    margin-top:40px;
  }
}
.mt-block {
  margin-top:120px;
  @include below($s) {
    margin-top:40px;
  }
}
.m-block {
  margin: 120px auto;
  @include below($m) {
    margin:60px auto;
  }
}
.m-block-auto {
  margin: 120px 0;
  @include below($m) {
    margin:60px 0;
  }
}
.my-4 {
  margin:40px 0;
  @include below($m) {
    margin:30px 0 !important;
  }
}
.my-6 {
  margin:60px 0;
  @include below($m) {
    margin:50px 0 !important;
  }
}
.my-8 {
  margin:80px 0;
  @include below($m) {
    margin:60px 0 !important;
  }
}
.mb-1 {
  margin-bottom:10px;
}
.mb-2 {
  margin-bottom:20px;
}
.mb-3 {
  margin-bottom:30px;
}
.mb-4 {
  margin-bottom:40px;
}
.mb-5 {
  margin-bottom:50px;
  @include below($s) {
    margin-bottom:40px;
  }
}
.mb-6 {
  margin-bottom:60px;
  @include below($s) {
    margin-bottom:40px;
  }
}
.mb-7 {
  margin-bottom:70px;
  @include below($s) {
    margin-bottom:40px;
  }
}
.mb-8 {
  margin-bottom:80px;
  @include below($s) {
    margin-bottom:40px;
  }
}
.mb-block {
  margin-bottom:120px;
  @include below($s) {
    margin-bottom:40px;
  }
}
.ml-1 {
  margin-left:10px;
}
.mx-1 {
  margin:0 10px;
}
.mx-auto {
  margin-left:auto;
  margin-right:auto;
}
.m-nm {
  @include below($s) {
    margin:0 !important;
  }
}
.m-mb2 {
  @include below($m) {
    margin-bottom:20px !important;
  }
}
/**
 *  Padding
 */

 .pt-block {
  padding-top:105px;
  @include below($s) {
    padding-top:40px;
  }
}
.pb-block {
  padding-bottom:105px;
  @include below($s) {
    padding-bottom:40px;
  }
}
.p-block {
  padding:105px 0;
  @include below($s) {
    padding:40px 0;
  }
}
.py-block-l {
    padding: 177px 0;
    @include below($s) {
        padding: 40px 0;
    }
}
.py-block-md {
    padding: 120px 0;
    @include below($s) {
        padding: 60px 0;
    }
}
.py-block-s {
    padding: 80px 0;
    @include below($s) {
        padding: 40px 0;
    }
}
.py-4 {
  padding:40px 0;
}
.py-5 {
  padding:50px 0;
  @include below($m) {
    padding:40px 0;
  }
}
.py-7 {
  padding:70px 0;
  @include below($m) {
    padding:35px 0;
  }
}
.p-20 {
  padding:20px;
  @include below($s) {
    padding:0;
  }
}
.m-no-pad {
  @include below($m) {
    padding:0 !important;
  }
}
.m-px-20 {
  @include below($m) {
    padding:0 20px !important;
  }
}
.pb-4 {
  padding-bottom:40px;
}
.pb-6 {
  padding-bottom:60px;
}
.pt-6 {
  padding-top:60px;
}
.px-1 {
  padding:0 10px;
}
.py-1 {
  padding:10px 0;
}
.py-50 {
  padding:50px 0;
}
.py-80 {
  padding:80px 0;
}
.py-100 {
  padding:100px 0;
}
.px-2 {
  padding-left:20px;
  padding-right:20px;
}
.pl-80 {
    padding-left: 100px;
}
.pr-80 {
    padding-right: 100px;
}
.pr-170 {
    padding-right: 170px;
}
.pl-170 {
  padding-left: 170px;
}
.pr-100 {
    padding-right: 100px;
}
// Text
.bold {
  font-weight:bold;
}
.title-font {
  font-family:$titleFont;
}
.body-font {
  font-family:$bodyFont;
}

.t-xl {
  font-size:160px;
  line-height:100px;
  @include below($m) {
    font-size:40px;
    line-height:30px;
  }
}
.t-l {
  font-size:68px;
  line-height:auto;
  @include below($m) {
    font-size:40px;
    line-height:auto;
  }
}
.t-xmd {
  font-size:58px;
  line-height:auto;
  @include below($m) {
    font-size:40px;
    line-height:auto;
  }
}
.t-md {
  font-size:36px;
  line-height:auto;
  @include below($m) {
    font-size:28px;
    line-height:auto;
  }
}
.t-std {
  font-size:24px;
  line-height:38px;
  @include below($m) {
    font-size:18px;
    line-height:auto;
  }
}
.t-s {
  font-size:18px;
  line-height:28px;
  @include below($m) {
    font-size:16px;
    line-height:24px;
  }
}
.t-xs {
  font-size:16px;
  line-height:24px;
  @include below($m) {
    font-size:14px;
    line-height:22px;
  }
}
.uppercase {
  text-transform: uppercase;
}
.underline {
  text-decoration:underline !important;
}
.t-center {
  text-align:center;
}

.flex-reverse {
  flex-direction: row-reverse;
}
.block {
  display:block;
}
.relative {
  position:relative;
}
.section-title {
  position:relative;
  @extend .mb-8;
  margin-top:200px;
  @include below($s) {
    margin-top:80px;
  }
  h2 {
    background:white;
    position: relative;
    z-index:5;
    padding-right:30px;
    display:inline-block;
  }
  &:after {
    content:'';
    width:100%;
    height:3px;
    z-index:2;
    background:map-get($colors, black);
    position:absolute;
    top:50%;
    margin-bottom:-3px;
    left:0;
  }
}
.ilb {
  display:inline-block;
}